import React, { useRef } from "react";
import { graphql, PageProps } from "gatsby";

// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import { getNextPrograms } from "../helpers";
// import Image from "../components/image";
// import CarouselCard from "../components/carousel-card";
// import GradientButton from "../components/gradient-button";
// import Play from "../components/svg/play";
// import SharedIcon from "../components/svg/shared";

import '../assets/css/index.css';
import ArrowUp from "../components/svg/arrow-up";

const IndexPage: React.FC<PageProps<any>> = ({ data }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { pageImage, site, heroLeftBg, heroRightBg, aboutLeftBg, actualitesBg, animateursBg, videoPoster } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.home;
  const programs = getNextPrograms();
  const responsive = {
    0: { items: 1 },
    500: { items: 2 },
    1024: { items: 3 },
  };
  const responsiveAnimateurs = {
    0: { items: 1 },
    500: { items: 2 },
    768: { items: 3 },
    1201: { items: 4 },
  };

  // const items = [
  //   <CarouselCard imageSrc={heroLeftBg?.childImageSharp?.fluid} title='Title' description='Lorem ipsum dolor sit amet, cons ectetr' time='20 : 45' date='2000.12.12' />,
  //   <CarouselCard imageSrc={heroLeftBg?.childImageSharp?.fluid} title='Title' description='Lorem ipsum dolor sit amet, cons ectetr' time='20 : 45' date='2000.12.12' />,
  //   <CarouselCard imageSrc={heroLeftBg?.childImageSharp?.fluid} title='Title' description='Lorem ipsum dolor sit amet, cons ectetr' time='20 : 45' date='2000.12.12' />,
  //   <CarouselCard imageSrc={heroLeftBg?.childImageSharp?.fluid} title='Title' description='Lorem ipsum dolor sit amet, cons ectetr' time='20 : 45' date='2000.12.12' />,
  //   <CarouselCard imageSrc={heroLeftBg?.childImageSharp?.fluid} title='Title' description='Lorem ipsum dolor sit amet, cons ectetr' time='20 : 45' date='2000.12.12' />,
  // ];

  // const animateursItems = [
  //   <CarouselCard
  //     withArcs
  //     imageSrc={heroLeftBg?.childImageSharp?.fluid}
  //     imageClassName='carousel__circle position-relative'
  //     className='d-flex justify-content-center flex-column text-center position-relative'
  //     title='ipsum dolor sit amet, cons'
  //     description='Lorem ipsum dolor sit amet, cons ectetr'
  //   />,
  //   <CarouselCard
  //     withArcs
  //     imageSrc={heroLeftBg?.childImageSharp?.fluid}
  //     imageClassName='carousel__circle position-relative'
  //     className='d-flex justify-content-center flex-column text-center'
  //     title='ipsum dolor sit amet, cons'
  //     description='Lorem ipsum dolor sit amet, cons ectetr'
  //   />,
  //   <CarouselCard
  //     withArcs
  //     imageSrc={heroLeftBg?.childImageSharp?.fluid}
  //     imageClassName='carousel__circle position-relative'
  //     className='d-flex justify-content-center flex-column text-center'
  //     title='ipsum dolor sit amet, cons'
  //     description='Lorem ipsum dolor sit amet, cons ectetr' />,
  //   <CarouselCard
  //     withArcs
  //     imageSrc={heroLeftBg?.childImageSharp?.fluid}
  //     imageClassName='carousel__circle position-relative'
  //     className='d-flex justify-content-center flex-column text-center'
  //     title='ipsum dolor sit amet, cons'
  //     description='Lorem ipsum dolor sit amet, cons ectetr' />,
  //   <CarouselCard
  //     withArcs
  //     imageSrc={heroLeftBg?.childImageSharp?.fluid}
  //     imageClassName='carousel__circle position-relative'
  //     className='d-flex justify-content-center flex-column text-center'
  //     title='ipsum dolor sit amet, cons'
  //     description='Lorem ipsum dolor sit amet, cons ectetr' />,
  // ];
  /**
   * play/pause video
   */
  const onToggleVideo = () => {
    if (videoRef.current) {
      if (videoRef.current?.paused) {
        videoRef.current?.play();
        videoRef.current.nextElementSibling?.classList?.add('fade');
      } else {
        videoRef.current?.pause();
        videoRef.current.nextElementSibling?.classList?.remove('fade');
      }
    }
  }

  return (
    <Layout className='home__page relative overflow-hidden' size='auto' headerClassName='navbar-wrapper'>
      <SEO title={title} description={description} path={path} image={pageImage?.childImageSharp?.fluid?.src} />
      <Hero
        leftImage={heroLeftBg?.childImageSharp?.fluid}
        rightImage={heroRightBg?.childImageSharp?.fluid}
        data={programs}
      />

      {/* <div className="content-visibility_auto contain-intrinsic-size_500 bg-greylighten py-3 py-md-5">

        <div className="container py-0 py-md-3">
          <div className="row container__row">
            <div className="pl-0 d-none d-lg-flex col-md-0 col-lg-4">
              <Image
                alt="radio mircophone a cote d'un PC"
                className='about-image w-100'
                src={aboutLeftBg?.childImageSharp?.fluid}
              />
            </div>
            <div className="pr-0 col-sm-12 col-md-12 col-lg-8">
              <h2 className="title mt-0 mb-4">À propos de nous</h2>
              <div className="text-content">
                <p className='m-0 mb-4'>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                  sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                  quis nostrud exerci tation ullamcorper suscipit lobortis nisl
                  ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                  dolor in hendrerit in vulputate velit esse molestie consequat,
                  vel illum dolore eu feugiat nulla facilisis at vero eros et
                  accumsan et iusto odio dignissim qui blandit praesent luptatum
                  zzril delenit augue duis dolore te feugait nulla facilisi.
                  Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit,
                  sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magna aliquam erat volutpat. Ut wisi enim ad
                </p>
                <ul className='list'>
                  <li className='list-item pl-0 pl-md-3 mb-4 position-relative'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</li>
                  <li className='list-item pl-0 pl-md-3 mb-4 position-relative'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna </li>
                  <li className='list-item pl-0 pl-md-3 mb-4 position-relative'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna </li>
                  <li className='list-item pl-0 pl-md-3 mb-4 position-relative'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna </li>
                  <li className='list-item pl-0 pl-md-3 position-relative'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="content-visibility_auto contain-intrinsic-size_500 bg-darkengrey py-4">
        <div className="container">
          <div className="row container__row">
            <div className="col-sm-12 mb-4">
              <h2 className="title text-center m-0">Galerie de photos</h2>
            </div>
            <div className="carousel__container overflow-hidden">
              <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="content-visibility_auto contain-intrinsic-size_500 bg-gradient position-relative">

        <Image
          src={actualitesBg?.childImageSharp?.fluid}
          className='blend-mode_luminosity opacity_5 position-absolute w-100 h-100'
          alt='dark abstract texture' />

        <div className="container py-4 position-relative">
          <div className="row container__row">
            <h2 className="col title text-white text-uppercase text-center m-0 mb-3">Actualités</h2>
            <p className="m-0 text-white fsz-18 font-weight-normal mb-4">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit e
            </p>
            <div className="row">
              <div className="col-sm-12 col-md-6 mb-4 mb-md-0">
                <CarouselCard
                  className='m-0'
                  title={'ipsum dolor sit amet, '}
                  imageSrc={heroLeftBg?.childImageSharp?.fluid}
                  description={`Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                  sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                  quis nostrud exerci tation ullamcorper suscipit lobortis
                  nisl ut aliquip ex ea commodo consequat. Duis autem vel
                  eum iriure dolor in hendrerit in vulputate velit esse molestie
                  consequat, vel illum dolore eu feugiat nulla facilisis at
                  vero eros et accumsan et iusto odio dignissim qui blandit
                  praesent luptatum zzril delenit augue duis d`} />
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-sm-6 col-md-6">
                    <CarouselCard
                      imageClassName='card__image-container'
                      className='m-0 actualites-card_size_small'
                      title={'ipsum dolor sit amet, '}
                      imageSrc={heroLeftBg?.childImageSharp?.fluid}
                      description={`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad `} />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <CarouselCard
                      imageClassName='card__image-container'
                      className='m-0 actualites-card_size_small'
                      title={'ipsum dolor sit amet, '}
                      imageSrc={aboutLeftBg?.childImageSharp?.fluid}
                      description={`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad `} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <CarouselCard
                      imageClassName='card__image-container'
                      className='m-0 actualites-card_size_small'
                      title={'ipsum dolor sit amet, '}
                      imageSrc={heroRightBg?.childImageSharp?.fluid}
                      description={`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad `} />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <CarouselCard
                      imageClassName='card__image-container'
                      className='m-0 actualites-card_size_small'
                      title={'ipsum dolor sit amet, '}
                      imageSrc={heroLeftBg?.childImageSharp?.fluid}
                      description={`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad `} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="content-visibility_auto contain-intrinsic-size_500 bg-black py-4">
        <div className="container">
          <div className="row container__row">
            <div className="col-sm-12 mb-4">
              <h2 className="title text-center m-0 mb-3">Nos Dj’s résident</h2>
              <p className="m-0 text-white fsz-18 font-weight-normal mb-4">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit e
              </p>
            </div>
            <div className="carousel__container overflow-hidden">
              <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="content-visibility_auto contain-intrinsic-size_500 bg-gradient-dark position-relative overflow-hidden">

        <Image
          src={animateursBg?.childImageSharp?.fluid}
          className='blend-mode_luminosity opacity_5 position-absolute w-100 h-100'
          alt='dark abstract texture' />

        <div className="container py-4">
          <div className="row container__row">
            <div className="col-sm-12 mb-4">
              <h2 className="title text-center m-0 mb-3 text-white">Nos Animateurs</h2>
              <p className="m-0 text-white fsz-18 font-weight-normal mb-4">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit e
              </p>
            </div>
            <div className="carousel__container carousel_theme_circle overflow-hidden">
              <AliceCarousel
                mouseTracking
                items={animateursItems}
                responsive={responsiveAnimateurs}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="content-visibility_auto contain-intrinsic-size_500 bg-darkengrey pt-4 pb-5" id='video-live'>
        <div className="container">
          <div className="mb-4">
            <h2 className="title text-center m-0">Video live</h2>
          </div>
          <div className="row container__row">
            <div className="col-sm-12 col-lg-4 d-flex justify-content-between flex-column text-white mb-4 mb-lg-0">
              <p className="m-0 fsz-16 font-weight-normal mb-4">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                quis nostrud exerci tation ullamcorper suscipit lobortis
                nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
                iriure dolor in hendrerit in vulputate velit esse molestie
                consequat, vel illum dolore eu feugiat nulla facilisis at
                vero eros et accumsan et iusto odio dignissim
              </p>
              <GradientButton>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${site?.siteMetadata?.siteUrl}`} className='text-white' rel='noopener noreferrer' target='_blank'>
                  <span className='mr-4'>Partager</span>
                  <SharedIcon />
                </a>
              </GradientButton>
            </div>
            <div className="col-sm-12 col-lg-8">
              <div className="w-100 video__container d-flex justify-content-center position-relative">
                <span className="title m-0 video__live text-uppercase fsz-20 font-weight-bold">Live</span>
                <video
                  poster={videoPoster?.childImageSharp?.fluid?.src}
                  src="https://www.w3schools.com/html/mov_bbb.mp4"
                  className='w-100 video__item'
                  onClick={onToggleVideo}
                  ref={videoRef}
                />
                <div role='button' onClick={onToggleVideo} className="video__play-icon position-absolute d-flex justify-content-center align-items-center">
                  <Play height={43} width={50} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <a href="#top__block" id="scroll-to-top" className='btn-up position-fixed'>
        <ArrowUp />
      </a>

    </Layout>
  );
}

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        lang
        siteUrl
        pages {
          home {
            title
            path
            description
          }
        }
      }
    }
    pageImage: file(relativePath: { eq: "algone-bg-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroLeftBg: file(relativePath: { eq: "home/left-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroRightBg: file(relativePath: { eq: "home/right-bg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    aboutLeftBg: file(relativePath: { eq: "home/about/retro-microphone-notebook-computer-live-webcast-air-concept.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    actualitesBg: file(relativePath: { eq: "home/actualites/dark-abstract-texture.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    animateursBg: file(relativePath: { eq: "home/animateurs/radio-room.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    videoPoster: file(relativePath: { eq: "home/video/video-poster.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage
