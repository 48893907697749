import { KeyValueObject } from "../types/shared";
import grillPrograms from '../config/contents/grill.json';

/**
   * retrieve list of programs of giver day by it index [0-6] or sunday-saturday
   * @param day present the number of day returned from getDay() of Date
   */
const getProgramsByDayIndex = (day: number): KeyValueObject<string>[] => {
  const currentDayName = Object.keys(grillPrograms)[day];
  // @ts-ignore
  return grillPrograms[currentDayName];
}

/**
 * retrieve 2 next programs after current one
 */
export const getNextPrograms = () => {
  const _date = new Date();
  const currentDay = _date.getDay();
  const currentHour = _date.getHours();
  const currentMinutes = _date.getMinutes();

  const currentDayPrograms = getProgramsByDayIndex(currentDay);
  const currentProgramIndex = currentDayPrograms.findIndex((program: KeyValueObject<string>, inx: number) => {
    const [startH, startM] = program.startTime.split(':');
    const [endH, endM] = program.endTime.split(':');

    return (
      currentHour >= parseInt(startH, 10) &&
      currentHour <= parseInt(endH, 10)
    );
  });

  let nextPrograms: any[] = currentProgramIndex === -1
    ? []
    : currentDayPrograms.filter((p, i) => {
      return i - currentProgramIndex === 1 || i - currentProgramIndex === 2
    });

  // get 2next programs if not from this day the next one(if it close to the end of current day at midnight)
  if (nextPrograms.length === 0) {
    nextPrograms = currentDay < 6
      ? getProgramsByDayIndex(currentDay + 1).slice(0, 2)
      : getProgramsByDayIndex(0).slice(0, 2);
  } else if (nextPrograms.length === 1) {
    nextPrograms = currentDay < 6
      ? [
        ...nextPrograms,
        ...getProgramsByDayIndex(currentDay + 1).slice(0, 1)
      ]
      : [
        ...nextPrograms,
        ...getProgramsByDayIndex(0).slice(0, 1)
      ]
      ;
  }
  return nextPrograms;
}