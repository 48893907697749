import React from 'react'

export default function ArrowUp() {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M61.0361 30.5183C61.0361 47.3816 47.3811 61.0361 30.5178 61.0361C13.6545 61.0361 0 47.3816 0 30.5183C0 13.655 13.6545 0 30.5178 0C47.4558 0 61.0361 13.655 61.0361 30.5183Z" fill="url(#arrow-up-0)" />
      <path d="M30.375 19.945C30.9983 19.945 31.5717 20.2441 31.9208 20.7552L43.2894 37.1225C43.8877 37.9826 43.6758 39.1544 42.8157 39.7527C41.9555 40.3511 40.7838 40.1392 40.1979 39.279L30.375 25.1306L20.5522 39.2666C19.9539 40.1267 18.7821 40.3386 17.9344 39.7403C17.0743 39.1419 16.8624 37.9702 17.4607 37.11L28.8293 20.7428C29.1783 20.2441 29.7518 19.945 30.375 19.945Z" fill="white" />
      <defs>
        <linearGradient id="arrow-up-0" x1="8.96067" y1="52.121" x2="52.1374" y2="8.94426" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
      </defs>
    </svg>
  )
}
