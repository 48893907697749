import React from 'react'
import { KeyValueObject } from '../../types/shared';
import Card from '../card'
import Image from '../image'

interface HeroPropsType {
  leftImage: any;
  rightImage: any;
  data: KeyValueObject<string>[];
}

export default function Hero({ leftImage, rightImage, data: programs }: HeroPropsType) {
  return (
    <div className='hero position-relative content-visibility_auto contain-intrinsic-size_500'>
      <Image src={leftImage} className='hero__image hero_direction_left h-100 w-75 position-absolute' alt="homme face un micro radio" />
      <Image src={rightImage} className='hero__image hero_direction_right h-100 w-55 position-absolute' alt="homme face un micro radio" />
      <div className="container h-100 position-relative text-white">
        <div className="row container__row h-100 d-flex flex-row align-items-center">
          <div className="hero__header w-55">
            <h1 className="hero__title text-uppercase font-weight-light">
              <strong className="font-weight-bold">ALGONE</strong>, THE <strong className="font-weight-bold">RADIO</strong> stay tuned.
            </h1>
          </div>
          <div className=" d-none d-md-flex hero__content flex-column flex_1 pl-0 pl-md-4">
            <div className="hero__title text-uppercase font-weight-bold">
              Next
            </div>
            <div className="hero__carousel d-flex w-auto position-relative pt-3">
              {
                programs.map(({ slug, startTime, title }) => (
                  <Card
                    key={slug}
                    className='mr-3'
                    imageSrc={leftImage}
                    startTime={startTime}
                    title={title}
                    headerClassName='hero__card-header'
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}